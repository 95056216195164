<template>
  <div>
    <div v-for="(link, idx) in links" :key="idx" style="margin: 10px">
      <MiniCard
        :link="link.metadata.link_to"
        v-if="size === 'sm'"
        :title="link.metadata.text"
        :image="link.metadata.image.imgix_url"
        :tags="hashTags(link)"
        :text="link.metadata.caption"
      ></MiniCard>

      <div
        class="card text-center col-sm-4 col-md-8 centered"
        v-if="size === 'lg'"
        style="background-color: #1f1e1e"
      >
        <router-link :to="{ path: link.metadata.link_to }">
          <img
            v-lazy="
              link.metadata.image.imgix_url +
              '?h=300&w=300&&fit=fillmax&fill=blur'
            "
            class="centered"
            height="300"
            width="300"
          />

          <div class="card-text text-center">
            <h4 class="tulum-party-subtitle">
              {{ link.metadata.text }}
            </h4>
          </div>
          <div class="text-center">
            <div v-if="link.slug.match(/youtube/i)">
              <img
                v-lazy="youTube"
                class="centered"
                height="20px"
                alt="youtube"
                width="20px"
                style="border-radius: 300px; border: 1px solid #221e1e"
              />
              <small>YouTube</small>
            </div>
            <div v-if="link.slug.match(/soundcloud/i)">
              <img
                v-lazy="soundCloud"
                class="centered"
                height="20px"
                alt="soundcloud"
                v-if="link.slug.match(/soundcloud/i)"
              />
              <small>SoundCloud</small>
            </div>
            <div v-if="link.slug.match(/spotify/i)">
              <img
                v-lazy="spotify"
                class="centered"
                height="20px"
                alt="spotify"
                v-if="link.slug.match(/spotify/i)"
                width="20px"
                style="border-radius: 300px; border: 1px solid #221e1e"
              />
              <small>Spotify</small>
            </div>
          </div>
          <p>
            {{ link.metadata.caption }}
          </p>

          <br />
        </router-link>
        <hr />
        <p>TAGS:</p>
        <div v-html="hashTagsWithLink(link)" class="text-center"></div>
        <br />
      </div>
    </div>
  </div>
</template>
<script>
import MiniCard from '@/components/Cards/MiniCard';

export default {
  name: 'DynamicLinks',
  components: { MiniCard },
  methods: {
    hashTagsWithLink(link) {
      return link.slug
        .split('-')
        .map((x) => `<a class='btn-link' href='#${x}'>#${x}</a>`)
        .join('');
    },
    hashTags(link) {
      return link.slug
        .split('-')
        .map((x) => `#${x}`)
        .join(' | ');
    },
  },
  data: () => {
    return {
      youTube:
        'https://imgix.cosmicjs.com/33d0d570-eaad-11ee-b074-b5c8fe3ef189-YouTube.jpg',
      soundCloud:
        'https://imgix.cosmicjs.com/9c356f90-eab2-11ee-b074-b5c8fe3ef189-Danleech-Simple-Soundcloud.1024.png',
      spotify:
        'https://imgix.cosmicjs.com/dab2a390-eab3-11ee-b074-b5c8fe3ef189-spotify.jpg',
    };
  },
  props: {
    links: {},
    size: {
      default: 'sm',
      type: String,
    },
  },
};
</script>
